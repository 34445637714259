

































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    Location: () => import(/* webpackChunkName: "cms" */'@/components/base/InsLocation.vue')
  }
})
export default class insSearch extends Vue {
     show: boolean = false;
    clickIndex: number = 0; // 点击菜单下标
    IsMobile: boolean = false;
      searchcontent: object[] = [];

      get storeSearchKey () {
        // return this.$store.state.searchKey;
        return this.$route.params.key;
      }
      // created () {
      //   this.$Api.cms.getsearch({ Title: this.storeSearchKey, IsMobile: this.isMobile }).then((result) => {
      //     this.searchcontent = result.Data;
      //   });
      // }
      // search内容
      getsearch () {
        this.$Api.cms.getsearch({ Title: this.storeSearchKey, IsMobile: this.isMobile, SortName: 'CreateDate', SortOrder: 'desc' }).then((result) => {
          this.searchcontent = result.Data;
          result.Data.forEach(function (item) {
            item.CreateDate = item.CreateDate.substring(0, item.CreateDate.indexOf(' ')
            );
          });
        });
      }

      // get SearchKey () {
      //   return this.$route.params.storeSearchKey;
      // }
      get isMobile () {
        return this.$store.state.isMobile;
      }

      mounted () {
        this.getsearch();
      }

    @Watch('storeSearchKey', { deep: true })
      onKeyChange () {
        this.getsearch();
      }

    @Watch('isMobile', { deep: true })
    onMediaChange () {
      this.getsearch();
    }
}
